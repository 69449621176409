<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">病区床旁通用医护患终端中心系统</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <div class="img">
            <img :src="imgUrl.img1"
                 alt="">
          </div>
          <div class="text bg">
            <p>病区床旁医护终端是未来病区床旁基础、必备设施,终端是医、护、患三方共用终端，将帮助医院实现现代医护，实现医疗前移、护理前移、患者共享医疗现代化与自助式治疗的新型治疗体系，实现医疗大数据化、人工智能化、自动化,大大提升医院医疗服务能力与质量，提高患者就医体验、和谐医患关系、改善医护人员工作强度、促进医护人员职业荣誉提升，促进医院基础设施装备水平提高。</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content bg">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>产品<em>特征</em></h2>
        <div class="list">
          <ul>
            <li>
              <em class="p1"></em>
              <h3>病例跟踪</h3>
            </li>
            <li>
              <em class="p2"></em>
              <h3>医护沟通</h3>
            </li>
            <li>
              <em class="p3"></em>
              <h3>远程会议</h3>
            </li>
            <li>
              <em class="p4"></em>
              <h3>病理分析</h3>
            </li>
            <li>
              <em class="p5"></em>
              <h3>学术支持</h3>
            </li>
            <li>
              <em class="p6"></em>
              <h3>临床医疗</h3>
            </li>
            <li>
              <em class="p7"></em>
              <h3>远程医疗</h3>
            </li>
            <li>
              <em class="p8"></em>
              <h3>医学影像</h3>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'doctor',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/bedside-banner.png')
    this.imgUrl.img1 = getAliyunImg.signatureUrl('ytwd/gw/bedside.png')
  },
  data () {
    return {
      imgUrl: {
        banner: '',
        img1: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  // background-image: url("../../assets/images/bedside-banner.png");
  .icon {
    background-image: url("../../assets/images/bedside-icon.png");
  }
}
.content {
  .product .details {
    align-items: center;
    .text {
      padding: 75px 30px;
      display: flex;
      height: auto;
      // align-self: flex-start;
      &.bg {
        background-size: 210px 207px;
      }
    }
    .img {
      // height: 535px;
      background: none;
    }
  }
}
</style>
